<template>
    <div class="list pb-nav home_list_header_padding">
        <HomeListHeader />
        
        <BasicList :list="list" v-if="list.length>0" v-model="selectSong" class="pb-res"></BasicList>
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                예약한 노래가 없어요.
            </p>
        </div>
        
        <Nav  @play-song="playSelectSong" @reserve-song-remove="ReservationRemove" ></Nav>
        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2 max-w-px-768 alert-danger"  style="width:95%" role="alert"
            v-if="showSystemAlert">
            <i class="fas fa-check me-2"></i> 
            <div>
                {{showSystemMemo}}
            </div>
        </div>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Header from '@/components/common/Header.vue'
import Nav from '@/components/common/Nav.vue'
import HomeListHeader from '@/components/common/HomeListHeader.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        BasicList,
        Header, Nav, HomeListHeader
    },
    data(){
        return{
            title:'예약곡',
            selectSong:null,
            list : [],
            showSystemMemo :'',
            showSystemAlert : false,
        }
    },
    mounted(){
        this.GetReservationList()
    },
    methods:{
        AddPlaySongReservation(){            

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddPlaySongReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const play_code = body.play_code;

                            this.$router.push({path : `/play-ready/${play_code}`});
                            
                        }else if(res.data.code =="120"){
                            this.showSystemMemo = "금일 참여가능 곡 수를 초과하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="100"){
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            alert();
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        CheckReservation(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/CheckReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.AddPlaySongReservation();
                        }else if(res.data.code =="100"){
                            
                            this.showSystemMemo = "노래를 선택해주세요."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        CheckTicket(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/CheckTicket',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            if (this.selectSong) {
                                this.AddPlaySong();   
                            }else{
                                this.CheckReservation();
                            }
                        }else if(res.data.code =="120"){
                            
                            this.showSystemMemo = "금일 참여가능 곡 수를 초과하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="100"){
                            
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        AddPlaySong(){
            const song = this.selectSong.item;
            const song_code = song.code;
            const nation = song.nation;
            const song_type = song.song_type;


            const body = {song_code,nation,song_type};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddPlaySong',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const play_code = body.play_code;

                            this.$router.push({path : `/play-ready/${play_code}`});
                            
                        }else if(res.data.code =="120"){
                            
                            this.showSystemMemo = "금일 참여가능 곡 수를 초과하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="100"){
                            
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        playSelectSong(){
            this.CheckTicket();

        },
        GetReservationList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/GetReservationList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.list = body.list;
                        }else if(res.data.code =="100"){
                            
                        }
                    }
                }
            );
        },
        ReservationRemove(){
            
            if (this.selectSong) {
                const item = this.selectSong.item;
                const index = this.selectSong.index;
                this.RemoveReservationList(item.code,item.nation,index);
                
            }else{
                
                this.showSystemMemo = "삭제할 예약을 선택해주세요."
                this.showSystemAlert = true;
                setTimeout(() => {
                    this.showSystemMemo = '';
                    this.showSystemAlert = false;
                }, 2000);
            }
        },
        RemoveReservationList(code,nation,index){
            
            const body = {code,nation};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/RemoveReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            // this.AlertShow('D','reservation');
                            this.showSystemMemo = "예약을 삭제했습니다."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                            this.selectSong = null;    
                            this.list.splice(index,1);
                            this.GetReservationList();
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
    }
}
</script>