<template>
    <div class="list pb-nav home_list_header_padding">
        <HomeListHeader />
        
        <BasicList :list="list"  v-model="selectSong" class="pb-res"></BasicList>
        <Nav @reserve-song="reserveSelectedSong" @play-song="playSelectSong"></Nav>
        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2 max-w-px-768 alert-danger"  style="width:95%" role="alert"
            v-if="showSystemAlert">
            <i class="fas fa-check me-2"></i> 
            <div>
                {{showSystemMemo}}
            </div>
        </div>
    </div>
    
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Header from '@/components/common/Header.vue'
import Nav from '@/components/common/Nav.vue'
import HomeListHeader from '@/components/common/HomeListHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        BasicList,
        Header,
        Nav,
        HomeListHeader
    },
    data(){
        return{
            showSystemAlert : false,
            showSystemMemo : '',
            title:'최신곡',
            selectSong:null,
            list : [],
            reservationAlert : false,
        }
    },
    computed:{
        trend(){
            return this.$store.state.all_songs;
        }
    },
    mounted(){
        this.GetRecentList();
    },
    methods:{
        AddPlaySongReservation(){            

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddPlaySongReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const play_code = body.play_code;

                            this.$router.push({path : `/play-ready/${play_code}`});
                            
                        }else if(res.data.code =="100"){
                            alert("보유 이용권 티켓이 없습니다.")
                        }else if(res.data.code =="300"){
                            alert("하루의 사용할 티켓을 모두 소진하였습니다.");
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        CheckReservation(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/CheckReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.AddPlaySongReservation();
                        }else if(res.data.code =="100"){
                            alert("노래를 선택해주세요.");
                        }else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        CheckTicket(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/CheckTicket',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            if (this.selectSong) {
                                this.AddPlaySong();   
                            }else{
                                this.CheckReservation();
                            }
                        }else if(res.data.code =="120"){
                            
                            this.showSystemMemo = "금일 참여가능 곡 수를 초과하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="100"){
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다.";
                            this.showSystemAlert = true;
                        }else if(res.data.code =="300"){
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다.";
                            this.showSystemAlert = true;
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        AddPlaySong(){
            const song = this.selectSong.item;
            const song_code = song.code;
            const nation = song.nation;
            const song_type = song.song_type;


            const body = {song_code,nation,song_type};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddPlaySong',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const play_code = body.play_code;

                            this.$router.push({path : `/play-ready/${play_code}`});
                            
                        }else if(res.data.code =="100"){
                            alert("보유 이용권 티켓이 없습니다.")
                        }else if(res.data.code =="300"){
                            alert("하루의 사용할 티켓을 모두 소진하였습니다.");
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        playSelectSong(){
            this.CheckTicket();

        },
        GetRecentList(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/GetRecentList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.list = body.list;
                        }else if(res.data.code =="100"){
                            
                        }
                    }
                }
            );            

        },
        
        AddReservation(code,nation){
            const body = {code,nation};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$router.push('/frequent');
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        AddReservationPopup(code,nation){
            this.reservationAlert = true;
            
            setTimeout(() => {
                
                this.reservationAlert = false;
            }, 400);
        },
        
        reserveSelectedSong() {
            if (this.selectSong) {
                const song = this.selectSong.item;
                const code = song.code;
                const nation = song.nation;
                // this.AddReservationPopup(code,nation);
                this.AddReservation(code,nation);
                
                this.selectSong = null;
            } else {
                this.$router.push('/frequent');
            }
        },
    }
}
</script>